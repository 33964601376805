export  default  defineNuxtRouteMiddleware(async (to, from) => {

    const currentUser: { role: string } | null | undefined = await useCurrentUser() as { role: string } | null | undefined
    const company = useCompany()

    if(!currentUser) {
        return navigateTo('/auth/signin')
    }

    // Simular não ter permissão
    // currentUser.role = 'user'

    if (!company) {        
        return navigateTo('/nocompany')
    }
    else if (currentUser.role !== 'recruiter' && currentUser.role !== 'admin') {
        return navigateTo('/norole')
    }
})